<template lang="pug">
div
  om-body-text.mb-4(bt400lg) {{ $t('codeInsertV2.help.sendToDev.text') }}
  .alert.alert-danger(v-if="error") {{ error }}
  form
    .form-row
      .form-group.col-6
        label.font-size-0--75(for="p_email") {{ $t('email') }}
        input#p_email.form-control(
          :class="{ 'is-invalid': $v.email.$error }"
          placeholder="your@dev.com"
          type="email"
          v-model="email"
        )
      .form-group.col-6
        label.font-size-0--75(for="p_name") {{ $t('sendName') }}
        input#p_name.form-control(
          :class="{ 'is-invalid': $v.name.$error }"
          placeholder="John Doe"
          type="text"
          v-model="name"
          @input="replaceName"
        )
    .form-group
      label.font-size-0--75(for="p_domain") {{ $t('domain') }}
      input#p_domain.form-control(
        :class="{ 'is-invalid': $v.domain.$error }"
        placeholder="yourdomain.com"
        type="text"
        v-model="domain"
      )
    .form-group
      om-textarea#p_note(v-model.trim="note" ref="note" rows="10" required :label="$t('message')")
  .d-flex.align-items-center.justify-content-end
    om-button(primary @click="send()") {{ $t('buttonMsg.send') }}
</template>

<script>
  // TODO: make business logic
  import SEND_INSERT_CODE from '@/graphql/SendInsertCode.gql';
  import { required, email } from 'vuelidate/lib/validators';
  import { mapGetters } from 'vuex';
  import { debounce } from 'lodash-es';
  import { getAccountIdFromCookie, validateDomain } from '@/util';
  import _insertCode from '@/config/insertCodes';
  import { track } from '@/services/xray';

  export default {
    data() {
      return {
        email: '',
        name: '',
        domain: '',
        note: '',
        error: null,
        campaign: null,
        lastReplacedDomain: '',
      };
    },
    computed: {
      ...mapGetters(['brandName', 'domains']),

      fallbackName() {
        return this.$i18n.locale === 'hu' ? '{{név}}' : '{{name}}';
      },

      namePlaceholder() {
        return this.$t('codeInsertV2.sendToDeveloper.placeholders.name');
      },

      initialNote() {
        const script = _insertCode('custom', getAccountIdFromCookie());

        return this.$t('codeInsertV2.sendToDeveloper.message', {
          name: this.$t('codeInsertV2.sendToDeveloper.placeholders.name'),
          script,
          domain: this.domain || '{{domain}}',
          brandName: this.brandName,
        });
      },
    },
    watch: {
      domain: debounce(function (newValue, oldValue) {
        let toReplace = this.lastReplacedDomain;
        if (!this.lastReplacedDomain) {
          toReplace = '{{domain}}';
        }
        this.lastReplacedDomain = oldValue;
        this.note = this.note.replace(toReplace, newValue);
      }, 1000),
    },
    validations: {
      email: {
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
        required,
      },
      name: {
        required,
      },
      note: {
        required,
      },
      domain: {
        validUrl(value) {
          return validateDomain(value);
        },
      },
    },
    methods: {
      reset() {
        this.clearFields();
        this.note = this.initialNote;

        this.$v.$reset();
      },
      async send() {
        this.$v.$touch();
        const noteValidation = this.$refs.note.validate();
        if (this.$v.$invalid || !noteValidation) {
          this.error = this.$t('notifications.validationError');
        } else {
          try {
            track('codeInsert-help-sendToDeveloper-send', null);
            const {
              data: { sendInsertCode },
            } = await this.$apollo.mutate({
              mutation: SEND_INSERT_CODE,
              variables: {
                input: {
                  email: this.email.toLowerCase(),
                  name: this.name,
                  note: this.note,
                },
              },
            });

            if (sendInsertCode.success) {
              this.$modal.hide('send-insert-code-modal');
              this.$notify({
                type: 'success',
                text: this.$t('notifications.sendSuccess'),
              });
              this.error = null;
            } else {
              throw new Error(sendInsertCode.message);
            }
          } catch (e) {
            this.error = this.$t(`notifications.${e.message}`);
          }
        }
      },
      preselectDomain() {
        const campaignId = this.$route.params.campaignId || this.$route.params.id;
        if (campaignId) {
          this.getCampaignDetails(campaignId);
        } else if (this.domains.length === 1) {
          this.domain = this.domains[0].domain;
        } else {
          this.domain = '';
        }
      },
      clearFields() {
        this.email = '';
        this.name = '';
        this.code = '';
        this.plugin = '';
        this.link = '';
        this.platform = '';
        this.domain = '';
        this.error = null;
      },
      replaceName: debounce(function () {
        if (this.name.length) {
          const toReplace =
            this.prevName && this.prevName.length ? this.prevName : this.namePlaceholder;
          this.note = this.note.replace(toReplace, this.name);
          this.prevName = this.name;
        }
      }, 500),
    },
  };
</script>

<style lang="sass">
  .send-insert-code-modal,
  .send-credentials-modal
    .brand-modal
      .brand-modal-header
        padding-top: 1.25rem
        padding-left: 2.8125rem
        padding-bottom: 1.25rem
        padding-right: 0
        border-bottom: 1px solid #E9EFF4

        .brand-modal-action-icon
          top: 1.3125rem
          right: 1rem
      .brand-modal-body
        padding: 2.0625rem 2.8125rem !important

        textarea
          min-height: 21rem
          line-height: 1.6
          padding-top: 0.5rem
          font-style: normal
      .brand-modal-footer
        padding: 0 !important
        margin: 0 !important
</style>
